export default {
  features: {
    addressFromLocalStorageEnabled: true,
    addressValidationInRegistrationEnabled: true,

    agreementsEblokEnabled: true,
    agreementsEmailSmsEnabled: true,
    agreementsGeneralProgramTermsTextEnabled: true,
    agreementsPersonalDataEnabled: false,
    agreementsSmsChannel: ['EMAIL', 'SMS'],
    agreementsStaffinoEnabled: false,
    agreementsThirdPartyMarketingEnabled: true,

    babyClubEnabled: false,
    birthDateEnabled: true,
    birthDateOverEnabled: 18,
    birthDateRequired: true,
    birthDateValidateOnSubmit: false,

    companyDataPrefillEnabled: false,
    companyItInvoicingEnabled: false,

    competitionNoticeEnabled: false,
    competitionService: {
      competitionImagePath: '',
    },

    correspondenceEmailEnabled: false,
    customerSupportWhatsappChannelEnabled: false,
    degreesEnabled: false,
    discountEnabled: true,

    /*eslint-disable */
    favoriteFilterCategoriesIds: [2942, 2959, 2977, 3000, 3056, 3023, 3102, 4202, 4715, 5484, 6564, 6517],

    favoritePharmaciesEnabled: true,
    favoriteProductsEnabled: true,

    loyaltyCardBackImagePath: '',
    loyaltyCardCodeLengthCheckEnabled: false,
    loyaltyCardImagePath: '/images/loyalty-card/general-loyalty-card.jpg',
    loyaltyCardMyProfileImagePath: '/images/loyalty-card/loyalty-card-my-profile-white.svg',
    loyaltyCardV2: false,

    cardCodeToOtpModeEnabled: true,
    fiscalCodeEnabled: false,
    loyaltyCardNumberDigits: 13,
    loyaltyCardNumberStaticPrefix: '',
    loyaltyProgramBenefitsCount: 5,
    myAccountSorpFromCustomerServiceEnabled: false,
    standAloneCardModeEnabled: false,
    mktPpdUnified: true,
    zipCodeRequired: true,
    checkPersonalDetailsCityFieldEnabled: false,
    accountInfoSorpUpgradeEnabled: false,

    phoneNumberRequired: true,
    phoneNumberSorpCheckEnabled: false,
    phoneCodeEnabled: true,

    priceEnabled: false,
    priceSummaryEnabled: true,
    registrationAddressEnabled: false,
    removeUserEnabled: false,
    returnAndComplaintTitleSplitEnabled: false,
    returnIbanFieldEnabled: false,
    showNotificationSettings: true,

    sexEnabled: false,
    sexThirdOptionEnabled: true,
    sexRequiredEnabled: true,
    typingInLoginEmailEnabled: false,
    unitPriceEnabled: true,
    ordersFilterFromEshopEnabled: false,

    emailRestrictedDomainEnabled: false,

    surveyEnabled: false,
    userFillableDetails:{
      pregnant: false,
      breastFeeding: false,
      allergies: false,
      treatments: false,
      height: false,
      weight: false,
    },
  },
}
